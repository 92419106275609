<template>
  <!-- forgot:password START -->
  <div class="account-password">

    <!-- form:forgot:password START -->
    <el-form
      status-icon
      :rules="rules"
      ref="registerForm"
      :model="registerForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('registerForm')"
    >
      <!-- form:forgot:password:new-password START -->
      <el-form-item prop="password">
        <el-input
          :type="type"
          :placeholder="`${$t('login.password')} *`"
          v-model="registerForm.password"
          @keyup.enter.native="submitForm('registerForm')"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:forgot:password:new-password END -->

      <!-- form:forgot:password:confirm-password START -->
      <el-form-item prop="confirmPassword">
        <el-input
          :type="type"
          :placeholder="`${$t('login.confirmpassword')} *`"
          v-model="registerForm.confirmPassword"
          @keyup.enter.native="submitForm('registerForm')"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:forgot:password:confirm-password END -->

      <!-- form:forgot:password:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('registerForm')"
        >{{ $t('login.save') }}</el-button>

      </el-form-item>
      <!-- form:forgot:password:submit END -->

    </el-form>
    <!-- form:forgot:password END -->

    <!-- form:forgot:password:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:forgot:password:loading END -->

    <!-- form:forgot:password:server:errors START
    <div
      class="global-server-error-message"
      v-show="serverErrorMessages.length"
      v-for="(message, messageIndex) in serverErrorMessages"
      :key="`messages-message-${messageIndex}`"
    >
      <span v-html="message.message"></span>
    </div>
    form:forgot:password:server:errors END -->

  </div>
  <!-- forgot:password END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import validateMixin from '@/mixins/validateMixin';

export default {
  name: 'forgot-password',
  mixins: [titleMixin, validateMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.password.headline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',
      oldType: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      messagePassword: this.$t('login.messagepassword'),
      confirmationPassword: this.$t('login.confirmationpassword'),
      registerForm: {
        password: '',
        confirmPassword: '',
        currentPassword: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        password: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validatePassword,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validateConfirmPassword,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'registerForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Data
             */
            formData.append('newpassword', this.registerForm.password);
            formData.append('renewPassword', this.registerForm.confirmPassword);
            formData.append('password', this.registerForm.currentPassword);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}password`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  const user = response.data;

                  // mutating to store for client rendering &
                  // saving user data in cookie for server rendering
                  this.updateItem('user', JSON.stringify(user));

                  // notification - error
                  this.success();
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    showHidePasswordOld() {
      if (this.oldType === 'password') {
        this.oldType = 'text';
      } else {
        this.oldType = 'password';
      }
    },

    updateItem(item, value) {
      const prevData = JSON.parse(localStorage.getItem(item));
      Object.keys(value).forEach((val) => {
        prevData[val] = value[val];
      });
      localStorage.setItem(item, JSON.stringify(prevData));
    },

    success() {
      this.$notify({
        message: this.$t('error.sentifexists'),
        type: 'success',
        position: 'bottom-right',
      });

      // clean up input fields
      setTimeout(() => {
        this.resetForm('resetPasswordForm');
      }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
